// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-HomePage-components-ResourceList-ResourceList-module__list--UgZVO{margin-top:48px;display:grid;grid-template-columns:repeat(3, 1fr);column-gap:16px;row-gap:16px}@media screen and (max-width: 920px){.app-pages-HomePage-components-ResourceList-ResourceList-module__list--UgZVO{grid-template-columns:repeat(2, 1fr)}}@media screen and (max-width: 600px){.app-pages-HomePage-components-ResourceList-ResourceList-module__list--UgZVO{grid-template-columns:1fr}}", "",{"version":3,"sources":["webpack://./app/pages/HomePage/components/ResourceList/ResourceList.module.scss"],"names":[],"mappings":"AAEA,6EACE,eAAA,CACA,YAAA,CACA,oCAAA,CACA,eAAA,CACA,YAAA,CAEA,qCAPF,6EAQI,oCAAA,CAAA,CAGF,qCAXF,6EAYI,yBAAA,CAAA","sourcesContent":["@import \"app/styles/utils/_colors.scss\";\n\n.list {\n  margin-top: 48px;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  column-gap: 16px;\n  row-gap: 16px;\n\n  @media screen and (max-width: 920px) {\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  @media screen and (max-width: 600px) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "app-pages-HomePage-components-ResourceList-ResourceList-module__list--UgZVO"
};
export default ___CSS_LOADER_EXPORT___;
