// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-edit-ProvidedService-module__addressSelect--Q9eih{border:1px solid #ddd;display:block;font-size:16px;margin-top:15px;padding:15px;width:100%}.app-components-edit-ProvidedService-module__addressList--qs9O6{display:flex;flex-direction:column;margin-bottom:12px;row-gap:2px}", "",{"version":3,"sources":["webpack://./app/components/edit/ProvidedService.module.scss"],"names":[],"mappings":"AAAA,kEACE,qBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CAGF,gEACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".addressSelect {\n  border: 1px solid #dddddd;\n  display: block;\n  font-size: 16px;\n  margin-top: 15px;\n  padding: 15px;\n  width: 100%;\n}\n\n.addressList {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 12px;\n  row-gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressSelect": "app-components-edit-ProvidedService-module__addressSelect--Q9eih",
	"addressList": "app-components-edit-ProvidedService-module__addressList--qs9O6"
};
export default ___CSS_LOADER_EXPORT___;
