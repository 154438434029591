// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-ui-HamburgerMenu-module__menuItem--nk06h{display:block;font-size:18px;line-height:1.4444444444;padding:12px 17px;border-bottom:1px solid #f4f4f4}.app-components-ui-HamburgerMenu-module__menuItem--nk06h.app-components-ui-HamburgerMenu-module__active--sB_JP{background:#276ce5;color:#fff}.app-components-ui-HamburgerMenu-module__menuItem--nk06h.app-components-ui-HamburgerMenu-module__emphasized--EBXvz{font-weight:600}", "",{"version":3,"sources":["webpack://./app/components/ui/HamburgerMenu.module.scss"],"names":[],"mappings":"AAAA,yDACE,aAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,+BAAA,CAEA,+GACE,kBAAA,CACA,UAAA,CAGF,mHACE,eAAA","sourcesContent":[".menuItem {\n  display: block;\n  font-size: 18px;\n  line-height: calc(26 / 18);\n  padding: 12px 17px;\n  border-bottom: 1px solid #f4f4f4;\n\n  &.active {\n    background: #276ce5;\n    color: #ffffff;\n  }\n\n  &.emphasized {\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "app-components-ui-HamburgerMenu-module__menuItem--nk06h",
	"active": "app-components-ui-HamburgerMenu-module__active--sB_JP",
	"emphasized": "app-components-ui-HamburgerMenu-module__emphasized--EBXvz"
};
export default ___CSS_LOADER_EXPORT___;
