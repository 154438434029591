// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-App-module__outerContainer--wxnC5{z-index:0}", "",{"version":3,"sources":["webpack://./app/App.module.scss"],"names":[],"mappings":"AAAA,uCAKE,SAAA","sourcesContent":[".outerContainer {\n  /* Creates a stacking context such that everything inside of the\n   * .outerContainer is either above or below anything outside of the\n   * .outerContainer. One example of something outside of the .outerContainer is\n   * modals. */\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": "app-App-module__outerContainer--wxnC5"
};
export default ___CSS_LOADER_EXPORT___;
