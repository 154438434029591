// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-Pdf-styles-module__pdfEmbed--JAoQL{height:98vh}.app-pages-Pdf-styles-module__handoutInvisible--pWAdj{visibility:hidden;height:0;overflow:hidden}.app-pages-Pdf-styles-module__loaderText--h9Swu{position:absolute;top:50%;left:50%;margin-left:-65px;width:130px}.app-pages-Pdf-styles-module__loaderText--h9Swu::after{display:inline-block;animation:app-pages-Pdf-styles-module__ellipsis--NWhyQ steps(1, end) 1.25s infinite;content:\"\"}@keyframes app-pages-Pdf-styles-module__ellipsis--NWhyQ{0%{content:\"\"}25%{content:\".\"}50%{content:\"..\"}75%{content:\"...\"}100%{content:\"\"}}", "",{"version":3,"sources":["webpack://./app/pages/Pdf/styles.module.scss"],"names":[],"mappings":"AAGA,8CACE,WAAA,CAGF,sDAEE,iBAAA,CACA,QAAA,CACA,eAAA,CAGF,gDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,iBAAA,CACA,WAAA,CACA,uDACE,oBAAA,CACA,mFAAA,CACA,UAAA,CAIJ,wDACE,GACE,UAAA,CAGF,IACE,WAAA,CAGF,IACE,YAAA,CAGF,IACE,aAAA,CAGF,KACE,UAAA,CAAA","sourcesContent":["// Set any styles here that we do not want to pass to the HTML -> PDF conversion API\n// E.g. showing/hiding loaders, etc.\n\n.pdfEmbed {\n  height: 98vh;\n}\n\n.handoutInvisible {\n  // Hides page from user while the PDF request is in flight\n  visibility: hidden;\n  height: 0;\n  overflow: hidden;\n}\n\n.loaderText {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-left: -65px;\n  width: 130px;\n  &::after {\n    display: inline-block;\n    animation: ellipsis steps(1, end) 1.25s infinite;\n    content: \"\";\n  }\n}\n\n@keyframes ellipsis {\n  0% {\n    content: \"\";\n  }\n\n  25% {\n    content: \".\";\n  }\n\n  50% {\n    content: \"..\";\n  }\n\n  75% {\n    content: \"...\";\n  }\n\n  100% {\n    content: \"\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfEmbed": "app-pages-Pdf-styles-module__pdfEmbed--JAoQL",
	"handoutInvisible": "app-pages-Pdf-styles-module__handoutInvisible--pWAdj",
	"loaderText": "app-pages-Pdf-styles-module__loaderText--h9Swu",
	"ellipsis": "app-pages-Pdf-styles-module__ellipsis--NWhyQ"
};
export default ___CSS_LOADER_EXPORT___;
