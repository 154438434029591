// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-ResourceGuides-ResourceGuides-module__resourceWrapper--fQmOE{margin:0 auto;padding:26px;max-width:1160px}.app-pages-ResourceGuides-ResourceGuides-module__resourceWrapper--fQmOE h1{font-size:36px;font-weight:bold}.app-pages-ResourceGuides-ResourceGuides-module__resourceFrameWrapper--tbGrn{display:flex;flex-direction:column;min-height:1280px}.app-pages-ResourceGuides-ResourceGuides-module__resourceFrame--KcMmO{flex:1;height:100%}", "",{"version":3,"sources":["webpack://./app/pages/ResourceGuides/ResourceGuides.module.scss"],"names":[],"mappings":"AAAA,wEACE,aAAA,CACA,YAAA,CACA,gBAAA,CAEA,2EACE,cAAA,CACA,gBAAA,CAIJ,6EACE,YAAA,CACA,qBAAA,CACA,iBAAA,CAGF,sEACE,MAAA,CACA,WAAA","sourcesContent":[".resourceWrapper {\n  margin: 0 auto;\n  padding: 26px;\n  max-width: 1160px;\n\n  h1 {\n    font-size: 36px;\n    font-weight: bold;\n  }\n}\n\n.resourceFrameWrapper {\n  display: flex;\n  flex-direction: column;\n  min-height: 1280px;\n}\n\n.resourceFrame {\n  flex: 1;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resourceWrapper": "app-pages-ResourceGuides-ResourceGuides-module__resourceWrapper--fQmOE",
	"resourceFrameWrapper": "app-pages-ResourceGuides-ResourceGuides-module__resourceFrameWrapper--tbGrn",
	"resourceFrame": "app-pages-ResourceGuides-ResourceGuides-module__resourceFrame--KcMmO"
};
export default ___CSS_LOADER_EXPORT___;
